// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/qbr-gradient.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qbr-section__8h0g7{font-family:\"Ciutadella\",Helvetica,Arial,sans-serif;color:#323232;line-height:1.5;font-weight:500;font-size:16px;background-color:#f6f6f6;border-top:1px solid #e6e6e6;border-bottom:1px solid #e6e6e6;padding:20px 16px;position:relative;display:block}.qbr-section__8h0g7>a{outline:3px solid rgba(0,0,0,0);outline-offset:-3px;text-decoration:underline;color:#e40000}.qbr-section__8h0g7>a:focus{outline-color:#8de2e0}.qbr-section__8h0g7>a:focus:not(:focus-visible){outline-color:rgba(0,0,0,0)}.qbr-section__8h0g7>a:focus-visible{outline-color:#8de2e0}.qbr-section__8h0g7>a:hover{color:#b60000}.qbr-section__8h0g7>a:active{color:#890000}.qbr-section__8h0g7::before{content:\"\";position:absolute;top:0;left:0;right:0;bottom:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:left -90px top -4px;background-size:220px;background-attachment:local}.qbr-section__heading__eQq86{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center}.qbr-section__link__tXedI{text-decoration:none}.qbr-section__gradient-mask__wkCXa{position:absolute;left:0;top:0;opacity:.5}.qbr-section__8h0g7:hover{text-decoration:none;cursor:pointer}.qbr-section__8h0g7:hover span{color:#b60000}.qbr-section__8h0g7:hover svg path{fill:#b60000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qbr-section": "qbr-section__8h0g7",
	"qbr-section__heading": "qbr-section__heading__eQq86",
	"qbr-section__link": "qbr-section__link__tXedI",
	"qbr-section__gradient-mask": "qbr-section__gradient-mask__wkCXa"
};
export default ___CSS_LOADER_EXPORT___;
