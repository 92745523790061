import {h} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import styles from './LoginProfileLinks.module.scss';
import {pushAnalyticsDataEvent} from '../../utils/pushAnalyticsDataEvent';
import ExternalLinkIcon from '../../assets/external-link-icon.svg';


interface Link {
    label: string;
    href: string;
}

const sendAnalytics = (eventLabel: string) => () => {
    pushAnalyticsDataEvent({
        event: {
            eventAction: 'CTA Click',
            eventCategory: 'Frequent Flyer Login',
            eventLabel
        },
        journey: {journeyStep: 'Logged In'}
    });
};

// For this one label we split the text and add styles to the second part
function processLabel(label: string) {
    const indexOfJoin = label?.indexOf('Join');
    if (indexOfJoin && indexOfJoin !== -1) {
        const labelSectionOne = label.slice(0, indexOfJoin)?.trim();
        const labelSectionTwo = label.slice(indexOfJoin)?.trim();
        return (
            <span>
        <span>{labelSectionOne}</span>
        <span className={`${styles['profile__link--join']}`}>
          {labelSectionTwo}
        </span>
      </span>
        );
    }
    return label;
}

function handleLoginClick(e) {
    e.preventDefault();
    window['oauth'].login(getLoginSuccessUrl(), 'qdd-login-ribbon');
}

function getLoginSuccessUrl() {
    return window['oauthConfig']?.successRedirectUrl || window.location.href;
}

export function LoginProfileLinks({authenticated}) {
    const [authedLinks, setAuthedLinks] = useState<Link[]>([]);
    const [unauthedLinks, setUnauthedLinks] = useState<Link[]>([]);

    const loginDialog = window[`loginDialog`];

    useEffect(() => {
        if (loginDialog?.model && loginDialog?.ui) {
            const authenticatedLinks: Link[] = [
                {
                    label: loginDialog.ui.frequentFlyerStatusLabel,
                    href: loginDialog.model.frequentFlyerStatusUri
                },
                {
                    label: loginDialog.ui.frequentFlyerHomeLabel,
                    href: loginDialog.model.frequentFlyerHomeUri
                },
                {
                    label: loginDialog.ui.frequentFlyerBookingsLabel,
                    href: loginDialog.model.frequentFlyerBookingUri
                },
                {
                    label: loginDialog.ui.frequentFlyerPointsLabel,
                    href: loginDialog.model.frequentFlyerPointsUri
                },
                {
                    label: loginDialog.ui.frequentFlyerProfileLabel,
                    href: loginDialog.model.frequentFlyerProfileUri
                }
            ];
            const unauthenticatedLinks: Link[] = [
                {
                    label: loginDialog.ui.frequentFlyerLoginLabel,
                    href: '' // <-- NOTE: USE oauth.login
                },
                {
                    label: loginDialog.ui.businessRewardsLoginLabel,
                    href: loginDialog.model.businessRewardsLoginUri
                },
                {
                    label: 'Not a member? Join here', // <-- We are keeping this hardcoded
                    href: loginDialog.model.frequentFlyerJoinUri
                }
            ];
            setAuthedLinks(authenticatedLinks);
            setUnauthedLinks(unauthenticatedLinks);
        }
    }, [loginDialog]);

    const authenticatedStyle = styles['qdd-profile__links--authenticated'];
    const unauthenticatedStyle = styles['qdd-profile__links'];
    const links = authenticated ? authedLinks : unauthedLinks;

    return (
        <ul className={authenticated ? authenticatedStyle : unauthenticatedStyle}>
            {links?.map((link, index) => {
                const label = processLabel(link.label);
                return (
                    <li
                        key={link}
                        className={`${styles['profile__link']}`}
                        onClick={
                            link.label.includes('Frequent Flyer login')
                                ? handleLoginClick
                                : undefined
                        }
                    >
                        <a href={link.href} onClick={sendAnalytics(link.label)}>
                            {label}
                            {authenticated ? (
                                <svg
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M9 16.7075L13.3266 12.5L9 8.2925L10.332 7L16 12.5L10.332 18L9 16.7075Z"
                                        fill="#323232"
                                    />
                                </svg>
                            ) : (
                                index < 2 && <img src={ExternalLinkIcon}  alt="" />
                            )}
                        </a>
                    </li>
                );
            })}
        </ul>
    );
}
