import type {StateUpdater} from 'preact/hooks';

export const capitaliseName = (name: string | undefined): string => {
  return name
    ? `${name
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')}`
    : '';
};

export const getCookieValue = (cookieKey: string): string | undefined => {
  const cookie = document.cookie
    ?.split('; ')
    .find((splitCookie) => splitCookie.startsWith(cookieKey));
  return cookie ? cookie.split('=')[1] : undefined;
};

export const isUserAuthenticated = (): boolean => {
  return window['qff_auth']?.isAuthenticated();
};

export function getUserMockName(string?: string) {
  if (string) {
    return string;
  }
  return '';
}

export function handleLongName(name: string) {
  if (name.length > 20) {
    return `${name.substring(0, 20)}...`;
  }
  return name;
}

export function handleEvent(
  event: KeyboardEvent,
  setMenuOpened?: StateUpdater<boolean>
) {
  if (event.key === 'Escape') {
    setMenuOpened?.(false);
    return;
  }
  if (event.key === 'Tab' || (event.key === 'Tab' && event.shiftKey)) {
    const nextElement = findNextTabStop(
      event.target as HTMLElement,
      event.shiftKey
    );
    if (!nextElement) {
      setMenuOpened?.(false);
    }
  }
}

export function findNextTabStop(el: HTMLElement, previous = false) {
  const tabbableElements = document.querySelectorAll(
    `#qdd-login-ribbon input, 
		#qdd-login-ribbon button,
		#qdd-login-ribbon select, 
		#qdd-login-ribbon textarea, 
		#qdd-login-ribbon a[href], 
		#qdd-login-ribbon [tabindex="0"]`
  );
  const list = Array.prototype.filter.call(
    tabbableElements,
    (item) => item.tabIndex >= '0'
  );
  const index = list.indexOf(el);
  let nextIndex: number;
  if (previous) {
    nextIndex = index - 1;
  } else {
    nextIndex = index + 1;
  }
  return list[nextIndex];
}
